var ko = require('../ExtendedKnockout.js');
var userModel = require('../model/user.js');
var jwt = require('jwt-simple');

function VerifyEmailComponentViewModel() {
	var self = this;
	self.mode = 'verifyemail';

	/* API Response Errors */
	self.error = ko.observable();
	self.errorDisplayMessage = ko.observable();

	/* Component State */
	self.nextViewMode = ko.observable().subscribeTo('nextViewMode', true);
	self.loading = ko.observable().syncWith('loading', true);
	self.verifyKey = ko.observable().subscribeTo('verifyKey', true);
	self.emailAlreadyVerified = ko.observable(false);
	self.success = ko.observable();

	/* Model Data */
	self.decoded = ko.computed(function() {
		if(self.verifyKey()) {
			var decoded = jwt.decode(self.verifyKey(), '', true);
			if (decoded.exp <= Date.now())
				return false;
			else
				return decoded;
		}
	});
	
	self.id = ko.computed(function() {
		if(self.decoded()) {
			return self.decoded().verify_id;
		}
	});
	self.expired = ko.computed(function() {
		if(self.decoded()) {
			if(self.decoded().exp <= Date.now())
				return true;
			else
				return false;
		}
	});

	/* Methods */
	self.verifyEmail = function() {
		userModel.acceptEmailVerification(self.id(), self.verifyKey())
			.done(function(data) {
				self.success(true);
				setTimeout(function () {
					location.hash = 'profile';
				}, 5000);
			})
			.fail(function(err) {
				if(err.status === 403) {
					self.errorDisplayMessage("Invalid or Expired Verification Token. " +
					                         "Please Resend");
				}
				else {
					console.log(err);
					self.error(err);
					self.errorDisplayMessage(err.message);
				}
			});
	};

	self.verifyKey.subscribe(function(key) {
		if(key) {
			if(self.decoded()) {
				self.ErrorDisplayMessage(false);
				self.verifyEmail();
			}
			else {
				self.errorDisplayMessage("Invalid or Expired Verification Token. " +
				                         "Please Resend");
			}
		}
	});

	if(self.nextViewMode() == 'verifyemail') {
		self.loading('done');
		if(self.verifyKey() && self.id())
			self.verifyEmail();
	}

}

module.exports = VerifyEmailComponentViewModel;
