var ko = require('../ExtendedKnockout.js');
var groupModel = require('../model/group.js');
var greenboxModel = require('../model/greenbox.js');
var swal = require('sweetalert');

function GroupComponentViewModel(params) {
	var self = this;
	self.mode = 'group';

	/* API Response Errors */
	self.error = ko.observable();
	self.errorDisplayMessage = ko.observable().publishOn('errorDisplayMessage');

	/* Component State */
	self.nextViewMode = ko.observable().subscribeTo('nextViewMode', true);
	self.loading = ko.observable().syncWith('loading', true);
	self.userId = ko.observable().subscribeTo('userId', true);
	self.userRole = ko.observable().subscribeTo('userRole', true);

	self.outstandingInvites = ko.observable(false);
	self.userIsGroupAdmin = ko.observable(false);
	self.editGroup = ko.observable(false);
	self.wait = ko.observable(false);
	self.inviteSuccess = ko.observable(false);
	self.updateSuccess = ko.observable(false);

	self.inviteSuccess.subscribe(function(success) {
		if(success) {
			self.retrieve();
		}
	});

	self.nextViewMode.subscribe(function(mode) {
		if(mode == self.mode) {
			self.resetFields();
			self.retrieve();
		}
	});

	self.id = params.id;

	self.displayInviteUser = ko.observable(false);
	self.displayAddGreenboxList = ko.observable(false);

	/* Model Data */
	self.group = new groupModel();
	self.originalGroup = new groupModel();
	self.demoted = ko.observableArray();
	self.promoted = ko.observableArray();
	self.remove = ko.observableArray();
	self.greenboxes = ko.observableArray();

	/* Methods */
	self.retrieve = function() {
		groupModel.retrieveGroup(self.id())
			.done(function(data) {
				ko.JSONtoVm(data.group, self.group);
				var originalGroup = JSON.parse(JSON.stringify(data.group));
				ko.JSONtoVm(originalGroup, self.originalGroup);
				self.userIsGroupAdmin(self.checkUserIsGroupAdmin());
				self.outstandingInvites(self.checkOutstandingInvites());
				self.loading('done');
				
				// console.log(data.group);
			})
			.fail(function(err) {
				self.error(err);
				self.errorDisplayMessage(err.responseJSON.error.description);
				console.log(err);
			});
	};

	self.update = function() {
		self.wait(true);

		var updatedGroup = self.checkGroupChanges();

		if(updatedGroup) {
			groupModel.updateGroup(self.id(), updatedGroup)
				.done(function(data) {
					self.wait(false);
					self.updateSuccess(true);
					self.editGroup(false);
				})
				.fail(function(err) {
					console.log(err);
					self.wait(false);
					self.updateSuccess(true);
					self.editGroup(false);
					self.errorDisplayMessage("Server replied with error");
				});
		}
		else {
			self.wait(false);	
		}
	};

	self.toggleInviteUser = function() {
		self.displayInviteUser(!self.displayInviteUser());
		self.displayAddGreenboxList(false);
	};

	self.toggleAddGreenboxList = function() {
		self.displayAddGreenboxList(!self.displayAddGreenboxList());
		self.displayInviteUser(false);
	};

	self.toggleEditGroup = function() {
		
		if(!self.updateSuccess() && self.editGroup()) {
			self.retrieve();
		}
		
		self.editGroup(!self.editGroup());
		self.updateSuccess(false);
	};

	self.flagRemoveUser = function(data, event) {
		self.remove.push(data.id);
		self.group.users.remove(data);
		self.update();
	};

	self.flagRemoveGreenbox = function(data, event) {
		self.greenboxes.push(data.id);
		self.group.greenboxes.remove(data);
		self.update();
	};

	self.checkUserIsGroupAdmin = function() {
		if(self.userRole() == 'root') {
			return true;
		}

		for(var user in self.group.users()) {
			if(self.group.users()[user].id == self.userId() &&
			   self.group.users()[user].isAdmin === true)
				return true;
		}
		
		return false;
	};

	self.checkOutstandingInvites = function() {
		for(var user in self.group.users()) {
			if(self.group.users()[user].invite_accepted === false) {
				return true;				
			}

		}
		return false;
	};

	self.checkGroupChanges = function() {
		for(var user in self.group.users()) {
			if(self.group.users()[user].isAdmin != self.originalGroup.users()[user].isAdmin) {
				if(self.group.users()[user].isAdmin) {
					self.promoted.push(self.group.users()[user].id);
				}	
				else {
					self.demoted.push(self.group.users()[user].id);
				}
			}
		}

		var updatedGroup = { };

		if(self.demoted().length > 0)
			updatedGroup.demote = self.demoted();
		
		if(self.promoted().length > 0)
			updatedGroup.admins = self.promoted();

		if(self.remove().length > 0)
			updatedGroup.remove = self.remove();

		if(self.group.name() != self.originalGroup.name()) 
			updatedGroup.name = self.group.name();

		if(self.greenboxes().length > 0)
			updatedGroup.greenboxes = self.greenboxes();

		if(self.group.description() != self.originalGroup.description())
			updatedGroup.description = self.group.description();

		if(!self.group.name.isValid() || !self.group.description.isValid()) {
			return null;
		}
		else {
			return updatedGroup;
		}
	};

	self.deleteGroup = function() {
		swal({
			title: "Confirm Delete Group",
			text: "Delete group?",
			type: "warning",
			html: true,
			showCancelButton: true,
			confirmButtonText: "Delete",
			closeOnConfirm: false
		},
		     function() {
			     groupModel.deleteGroup(self.id())
				     .done(function(data) {
					     if(data.success) {
						     swal("Deleted", "Group has been deleted", "success");
						     location.hash = 'groups';
					     }
				     })
				     .fail(function(err) {
					     console.log(err);
				     });			     
		     });
	};
	
	self.remoteAccess = function(id) {
		greenboxModel.getErasToken(id)
			.done(function(data) {
				location = "https://eras.ecogate.com/generatetoken/" + data.token;
			})
			.fail(function(err) {
				self.error(err);
				self.errorMessage(err);
			});
	};

	self.resetFields = function() {
		self.displayInviteUser(false);
		self.displayAddGreenboxList(false);
		self.outstandingInvites(false);
	};

	/* Initialization */
	if(self.nextViewMode() == self.mode) {
		self.resetFields();
		self.retrieve();
	}
}


module.exports = GroupComponentViewModel;
