// in ./js/model/user.js
var ko = require('../ExtendedKnockout.js');

function user() {
	var self = this;
	
	self.email = ko.observable().extend({
		required: {
			params: true,
			message: 'Email required'
		},
		email: true,
		maxLength: 254
	});
	self.email.field = 'email';
	
	self.password = ko.observable().extend({
		required: true,
		minLength: 6,
		maxLength: 32,
		zxcvbn_score: 2
	});
	self.password.field = 'password';
	
	self.first_name = ko.observable().extend({
		required: true,
		maxLength: 50
	});
	self.first_name.field = 'first_name';

	self.last_name = ko.observable().extend({
		required: true,
		maxLength: 50
	});
	self.last_name.field = 'last_name';

	self.timezone = ko.observable();
	self.timezone.field = 'timezone';

	self.phone_number_work = ko.observable().extend({
		pattern: {
			params: '^[0-9. .\(.\).\+.ext.-]*$',
			message: "ex: 011 +23 (444) 555-6789 ext. 55"
		},
		maxLength: 35
	});
	self.phone_number_work.field = 'phone_number_work';

	self.phone_number_cell = ko.observable().extend({
		pattern: {
			params: '^[0-9. .\(.\).\+.ext.-]*$',
			message: "ex: 011 +23 (444) 555-6789 ext. 55"
		},
		maxLength: 35
	});
	self.phone_number_cell.field = 'phone_number_cell';

	self.organization = ko.observable().extend({
		maxLength: 256
	});
	self.organization.field = 'organization';

	self.job_title = ko.observable().extend({
		maxLength: 50
	});
	self.job_title.field = 'job_title';

	self.email_verified = ko.observable();

	// Observable Array of values in this model that have
	// been updated
	self.changedVals = { };

	// Returns array of names of changedVals
	self.changedList = function() {
		var list = [];
		for(var prop in self.changedVals)
			list.push(self.changedVals[prop].field);
		return list;
	};

	// Subscribe to each observable to push it onto the
	// changedVals array when it changes
	self.trackVals = function(observable, required) {
		observable.subscribe(function(val) {
			if(required) {
				if(val)
					self.changedVals[observable.field] = observable;				
			}
			else {
				self.changedVals[observable.field] = observable;
			}				
		});
	};

	self.formErrors = ko.validation.group(self.changedVals, {deep: true});

	self.checkErrors = function() {
		self.formErrors = ko.validation.group(self.changedVals, {deep: true});
	};
	
	self.init = function () {
		self.trackVals(self.email, true);
		self.trackVals(self.first_name, true);
		self.trackVals(self.last_name, true);
		self.trackVals(self.organization);
		self.trackVals(self.job_title);
		self.trackVals(self.timezone);
		self.trackVals(self.phone_number_cell);
		self.trackVals(self.phone_number_work);
		self.trackVals(self.password, true);
	};
}

user.getTimeZones = function() {
	return ['US/Pacific', 'US/Mountain', 'US/Central', 'US/Eastern'];
};

user.retrieveUser = function(id) {
	return $.ajax({
		url: '/v1/users/' + id,
		type: 'GET'
	});
};

// Retrieve Users
user.retrieveUsers = function(search_string) {
	var url = '/v1/users?search=';

	if(search_string)
		url += search_string;

	return $.ajax({
		url: url,
		type: 'GET'
	});
};

// Update User
user.updateUser = function(id, updatedUser) {
	return $.ajax({
		url: '/v1/users/' + id,
		type: 'PUT',
		data: JSON.stringify({
			user: updatedUser
		})
	});
};

// Delete User
user.deleteUser = function(id) {
	return $.ajax({
		url: '/v1/users/' + id,
		type: 'DELETE'
	});
};

// Get Pending Invites
user.getPendingInvites = function(id) {
	return $.ajax({
		url: '/v1/users/' + id + '/invites',
		type: 'GET'
	});
};

// Accept Invite
user.acceptInvite = function(id, group_id) {
	return $.ajax({
		url: '/v1/users/' + id + '/invites',
		type: 'PUT',
		data: JSON.stringify({
			group_id: group_id
		})
	});
};

// Accept Verification Key
user.acceptEmailVerification = function(id, key) {
	return $.ajax({
		url: '/v1/users/' + id + '/verifyemail',
		type: 'PUT',
		data: JSON.stringify({
			key: key
		})
	});
};

// Send Verification Email
user.sendVerifyEmail = function(id) {
	return $.ajax({
		url: '/v1/users/' + id + '/verifyemail',
		type: 'GET',
	});
};

// Create User
user.createUser = function(user) {
	return $.ajax({
		url: '/v1/users/',
		type: 'POST',
		data: JSON.stringify({
			user: user
		})
	});
};

// Reset Password (send email)
user.resetPassword = function(email) {
	var emailEncoded = email.replace(/\+/, "%2B");
	
	var url = '/v1/resetpassword?email=' + emailEncoded;
	
	return $.ajax({
		url: url,
		type: 'GET'
	});
};

// Update Password (with password reset key)
user.updatePassword = function(email, password, key) {
	return $.ajax({
		url: '/v1/resetpassword',
		type: 'PUT',
		dataType: 'json',
		data: JSON.stringify({
			email: email,
			password: password,
			key: key
		})
	});
};

// Login
user.login = function(username, password) {
	return $.ajax({
		url: '/v1/login',
		type: 'POST',
		dataType: 'json',
		data: JSON.stringify({
			username: username,
			password: password
		})
	});
};

module.exports = user;
