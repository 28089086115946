// Function that accepts Knockout and registers all our custom components with it
var fs = require('fs');

module.exports = function(ko)
{
	ko.components.register('login',
	                       {
		                       viewModel: require('./components/login.js'), 
		                       template: require('../html/components/login.html')
	                       });
	ko.components.register('profile',
	                       {
		                       viewModel: require('./components/profile.js'),
		                       template: require('../html/components/profile.html')
	                       });
	ko.components.register('group-list',
	                       {
		                       viewModel: require('./components/groupList.js'),
		                       template: require('../html/components/groupList.html')
	                       });
	ko.components.register('greenbox-list',
	                       {
		                       viewModel: require('./components/greenboxList.js'),
		                       template: require('../html/components/greenboxList.html')
	                       });
	ko.components.register('group',
	                       {
		                       viewModel: require('./components/group.js'),
		                       template: require('../html/components/group.html')
	                       });
	ko.components.register('greenbox',
	                       {
		                       viewModel: require('./components/greenbox.js'),
		                       template: require('../html/components/greenbox.html')
	                       });
	ko.components.register('register',
	                       {
		                       viewModel: require('./components/register.js'),
		                       template: require('../html/components/register.html')
	                       });
	ko.components.register('forgot-password',
	                       {
		                       viewModel: require('./components/sendResetPassword.js'),
		                       template: require('../html/components/sendResetPassword.html')
	                       });
	ko.components.register('reset-password',
	                       {
		                       viewModel: require('./components/resetPassword.js'),
		                       template: require('../html/components/resetPassword.html')
	                       });
	ko.components.register('pending-invites',
	                       {
		                       viewModel: require('./components/pendingInvites.js'),
		                       template: require('../html/components/pendingInvites.html')
	                       });
	ko.components.register('verify-email',
	                       {
		                       viewModel: require('./components/verifyEmail.js'),
		                       template: require('../html/components/verifyEmail.html')
	                       });
	ko.components.register('create-group',
	                       {
		                       viewModel: require('./components/createGroup.js'),
		                       template: require('../html/components/createGroup.html')
	                       });
	ko.components.register('create-greenbox',
	                       {
		                       viewModel: require('./components/createGreenbox.js'),
		                       template: require('../html/components/createGreenbox.html')
	                       });
	ko.components.register('invite-user',
	                       {
		                       viewModel: require('./components/inviteUser.js'),
		                       template: require('../html/components/inviteUser.html')
	                       });
	ko.components.register('add-greenbox',
	                       {
		                       viewModel: require('./components/addGreenboxToGroup.js'),
		                       template: require('../html/components/addGreenboxToGroup.html')
	                       });
	ko.components.register('display-error',
	                       {
		                       viewModel: require('./components/error.js'),
		                       template: require('../html/components/error.html')
	                       });
	ko.components.register('user-list', 
	                       {
		                       viewModel: require('./components/userList.js'),
		                       template: require('../html/components/userList.html')
	                       });
	ko.components.register('user',
	                       {
		                       viewModel: require('./components/user.js'),
		                       template: require('../html/components/user.html')
	                       });
	ko.components.register('response-callout',
	                       {
		                       viewModel: require('./components/responseCallout.js'),
		                       template: require('../html/components/responseCallout.html')
	                       });
	ko.components.register('remote-access',
	                       {
		                       viewModel: require('./components/remoteAccess.js'),
		                       template: require('../html/components/remoteAccess.html')
	                       });
};
