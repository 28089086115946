var ko = require('../ExtendedKnockout.js');
var swal = require('sweetalert');
var groupModel = require('../model/group.js');

function InviteUserComponentViewModel(params) {
	var self = this;

	/* API Response Errors */
	self.error = ko.observable(null);
	self.errorDisplayMessage = ko.observable(null);
	
	/* Component State */
	self.active = params.active;
	self.id = ko.observable().subscribeTo('activeGroupId', true);
	self.viewMode = ko.observable().subscribeTo('viewMode');
	self.userInvitedSuccess = params.success;
	self.sendingInvite = ko.observable(false);
	
	self.active.subscribe(function(active) {
		if(!active)
			self.resetFields();
	});

	self.already = 0;

	/* Model Data */
	self.users = params.users;
	
	self.userToInvite = ko.observable()
		.extend({
			required: { params: true,
			            message: 'Please enter a required valid email address',},
			email: { params: true,
			         message: 'Please enter a email valid email address'},
			maxLength: 254
	});

	self.userToInvite.subscribe(function(email) {
		ko.utils.arrayForEach(self.users(), function(user) {
			if(email === user.email) {
				if(user.invite_accepted) {
					self.userToInvite.setError("User is already in group");
				}
				else {
					self.userToInvite.setError("User has already been invited");
				}
			}	
		});
	});
	
	self.asAdmin = ko.observable(false);
	
	/* Methods */
	self.inviteUser = function() {
		if(self.userToInvite.isValid()) {
			self.sendingInvite(true);
			groupModel.inviteUser(self.id(), self.userToInvite(), self.asAdmin())
				.done(function(data) {
					self.userInvitedSuccess(true);
					self.sendingInvite(false);
				})
				.fail(function(err) {
					self.error(err);
					self.errorDisplayMessage("User could not be invited");
					console.log(err);
					self.sendingInvite(false);
				});
		}
		else {
			if(email === user.email) {
				if(user.invite_accepted) {
					self.userToInvite.setError("User is already in group");
				}
				else {
					self.userToInvite.setError("User has already been invited");
				}
			}
			self.userToInvite.setError('Please provide the email of the user to invite');
			self.userToInvite.isModified(true);
		}
	};


	self.resetFields = function() {
		self.asAdmin(false);
		self.userInvitedSuccess(false);
		self.userToInvite(null);
		self.userToInvite.setError();
		self.error(null);
		self.errorDisplayMessage(null);
		self.userToInvite.isModified(false);
	};

}

module.exports = InviteUserComponentViewModel;
