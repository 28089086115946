var ko = require('../ExtendedKnockout.js');
var utils = require('../utils.js');
var groupModel = require('../model/group.js');
var greenboxModel = require('../model/greenbox.js');

function AddGreenboxToGroupComponentViewModel(params) {
	var self = this;

	/* API Response Errors */
	self.error = ko.observable();
	self.errorDisplayMessage = ko.observable();

	/* Component State */
	self.active = params.active;
	self.wait = ko.observable(false);

	/* Model Data */
	self.groupGreenboxes = params.groupGreenboxes;
	self.active = params.active;
	self.greenboxes = ko.observableArray();
	self.greenboxToAdd = ko.observable();
	self.id = ko.observable().subscribeTo('activeGroupId', true);

	self.groupGreenboxes.subscribe(function(greenboxes) {
		self.retrieveGreenboxList();
	});

	/* Methods */
	self.setInactive = function() {
		self.active(false);
	};
	
	self.retrieveGreenboxList = function() {
		greenboxModel.retrieveGreenboxes()
			.done(function(data) {
				self.groupGreenboxes().forEach(function(alreadyAdded) {
					for(var greenbox in data.greenboxes) {
						if(data.greenboxes[greenbox].id == alreadyAdded.id)
							data.greenboxes.splice(greenbox, 1);
					}
				});
				self.greenboxes(data.greenboxes);
			})
			.fail(function(err) {
				self.error(err);
				self.errorDisplayMessage(err);
				console.log(err);
			});
	};

	self.addGreenboxToGroup = function() {
		self.wait(true);
		groupModel.addGreenbox(self.id(), self.greenboxToAdd().id)
			.done(function(data) {
				self.retrieveGreenboxList();
				self.groupGreenboxes.push(self.greenboxToAdd());
				self.wait(false);
			})
			.fail(function(err) {
				self.wait(false);
				self.error(err);
				self.errorDisplayMessage(err);
				console.log(err);
			});
	};

}

module.exports = AddGreenboxToGroupComponentViewModel;
