var ko = require('../ExtendedKnockout.js');
var groupModel = require('../model/group.js');

function GroupListComponentViewModel(params) {
	var self = this;
	self.mode = 'groups';

	/* API Response Errors */
	self.error = ko.observable();
	self.errorDisplayMessage = ko.observable();

	/* Component State */
	self.nextViewMode = ko.observable(null).subscribeTo('nextViewMode', true);
	self.userRole = ko.observable().subscribeTo('userRole', true);
	self.loading = ko.observable().syncWith('loading');
	
	self.displayCreateGroup = ko.observable(false);
	

	self.nextViewMode.subscribe(function(mode) {
		if(mode == self.mode) {
			self.retrieve();
		}
	});

	/* Model Data */
	self.groups = ko.observableArray();

	/* Methods */
	self.retrieve = function() {
		groupModel.retrieveGroups()
			.done(function(data) {
				// console.log(data.groups);
				self.groups(data.groups);
				self.loading('done');
			})
			.fail(function(err) {
				self.error(err);
				self.errorDisplayMessage(err);
				console.log(err);
			});
	};

	self.toggleCreateGroup = function() {
		self.displayCreateGroup(!self.displayCreateGroup());
	};

	if(self.nextViewMode() == self.mode) {
		self.retrieve();		
	}
}

module.exports = GroupListComponentViewModel;
