// Convenience Utility Functions
var util = {};

util.isDefined = function(variable)
{
  if(typeof variable === 'undefined' || variable === null)
  {
    return false;
  }
  else
  {
    return true;
  }
};

util.isFunction = function(functionToCheck)
{
  var getType = {};
  return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
};

util.isObject = function(test)
{
  if(util.isDefined(test) && typeof test === 'object')
  {
    return true;
  }
  else
  {
    return false;
  }
};

module.exports = util;
