var ko = require('../ExtendedKnockout.js');
var userModel = require('../model/user.js');

var POLL_FOR_INVITES_INTERVAL = 60000; // ms

function PendingInvitesComponentViewModel(params) {
	var self = this;

	self.mode = 'invites';
	
	/* API Response Errors */
	self.error = ko.observable();
	self.errorDisplayMessage = ko.observable();

	/* Component State */
	self.active = params.active;
	self.id = ko.observable().subscribeTo('userId', true);
	self.nextViewMode = ko.observable().subscribeTo('nextViewMode', true);
	self.loading = ko.observable().syncWith('loading', true);

	// Gets the updated amount of pending invites every time
	self.nextViewMode.subscribe(function(mode) {
		if(mode == 'invites') {
			self.retrieve();
		}
	});


	/* Model Data */
	self.invites = ko.observableArray();
	self.numPendingInvites = ko.computed(function() {
		return self.invites().length;
	}).publishOn('pendingInvites');
	

	self.retrieveOnTimer = function() {
		setTimeout(function () {
			self.retrieveOnTimer();
			self.retrieve();
		}, POLL_FOR_INVITES_INTERVAL);
	};

	self.retrieveOnTimer();

	/* Methods */
	self.retrieve = function() {
		if(self.id() != undefined) {
			userModel.getPendingInvites(self.id())
				.done(function(data) {
					self.invites(data.invitesFrom);
					self.loading('done');
				})
				.fail(function(err) {
					self.error(err);
					self.errorDisplayMessage(err);
					console.log(err);
				});
		}
	};

	self.acceptInvite = function(group_id) {
		userModel.acceptInvite(self.id(), group_id)
			.done(function(data) {
				self.retrieve();
				location.hash='group/' + group_id;
			})
			.fail(function(err) {
				self.error(err);
				self.errorDisplayMessage(err);
				console.log(err);
			});
	};

	self.retrieve();
}

module.exports = PendingInvitesComponentViewModel;
