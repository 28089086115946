
var ko = require('../ExtendedKnockout.js');
var utils = require('../utils.js');
var userModel = require('../model/user.js');
var jwt = require('jwt-simple');

function ResetPasswordComponentViewModel(params) {
	var self = this;
	self.mode = 'resetpassword';

	/* API Response Errors */
	self.error = ko.observable();
	self.errorDisplayMessage = ko.observable();

	/* Component State */
	self.passwordWasReset = ko.observable(false);
	self.nextViewMode = ko.observable().subscribeTo('nextViewMode', true);
	self.loading = ko.observable().syncWith('loading', true);

	self.nextViewMode.subscribe(function(mode) {
		if(mode == self.mode)
			self.loading('done');
		
	});

	self.wait = ko.observable(false);

	/* Model Data */
	self.key = ko.observable().subscribeTo('resetKey', true);

	self.email = ko.observable()
		.extend({required: true,
		         email: true});

	self.password = ko.observable()
		.extend({required: true,
		         minLength: 6,
		         maxLength: 32,
		         zxcvbn_score: 2});

	self.confirmPassword = ko.observable()
		.extend({required: true,
		         areSame: {
			         params: self.password,
			         message: "Passwords do not match!" }});
	
	/* Methods */
	self.submit = function() {
		self.wait(true);
		var errors = ko.validation.group([
			self.email,
			self.password,
			self.confirmPassword
		]);

		if(errors().length === 0) {
			userModel.updatePassword(self.email(), self.password(), self.key())
				.done(function(data) {
					if(data.success == self.email())
						self.passwordWasReset(true);
					self.wait(false);
				})
				.fail(function(err) {
					self.error(err);
					self.errorDisplayMessage(err);
					console.log(err);
					self.wait(false);
				});			
		}
		else {
			errors.showAllMessages();
			self.wait(false);
		}

	};

	
	if(self.key()) {
		try {
			var decoded = jwt.decode(self.key(), '', true);
			if(decoded.exp <= Date.now())
				self.errorDisplayMessage("Password Reset Token Expired.  Please resend!");
			else {
				self.errorDisplayMessage(false);
				self.email(decoded.email);
			}
		}
		catch(err) {
			console.log(err);
		}
	}

	/* Init */
	if(self.nextViewMode() == self.mode)
		self.loading('done');
}

module.exports = ResetPasswordComponentViewModel;
