var ko = require('../ExtendedKnockout.js');
var LoginVM = require('./login.js');
var userModel = require('../model/user.js');

function RegisterComponentViewModel() {
    var self = this;
    self.mode = 'register';

    /* API Response Errors */
    self.error = ko.observable();
    self.errorDisplayMessage = ko.observable();

    /* Model Data */
    self.nextViewMode = ko.observable().subscribeTo('nextViewMode', true);
    self.loading = ko.observable().syncWith('loading', true);
    self.registerEmail = ko.observable().subscribeTo('registerEmail', true);
    self.wait = ko.observable(false);

    self.nextViewMode.subscribe(function(mode) {
        if (self.nextViewMode() == self.mode) {
            self.loading('done');
        }
    });

    /* Components State */
    self.user = new userModel();
    self.user.timezone('America/Los_Angeles');

    self.emailLink = ko.observable(false);
    self.registerMessage = ko.observable();

    self.checkUserStatus = function() {
        if (self.registerEmail() === undefined) {
            self.emailLink(false);
            self.registerMessage('...if you need to create an account');
        } else if (self.registerEmail() != undefined) {
            self.emailLink(true);
            self.registerMessage(
                'Account registration started with ' + self.registerEmail()
            );
        }
    };

    self.user.confirmPassword = ko.observable().extend({
        required: true,
        areSame: {
            params: self.user.password,
            message: 'Passwords do not match'
        }
    });

    self.user.confirmEmail = ko.observable().extend({
        required: true,
        areSame: {
            params: self.user.email,
            message: 'Emails do not match'
        }
    });

    self.formErrors = ko.validation.group(
        [
            self.user.email,
            self.user.confirmEmail,
            self.user.password,
            self.user.confirmPassword,
            self.user.first_name,
            self.user.last_name,
            self.user.phone_number_work,
            self.user.phone_number_cell,
            self.user.organization,
            self.user.job_title
        ],
        { deep: true }
    );

    /* Methods */
    self.submit = function() {
        self.wait(true);
        if (self.formErrors().length === 0) {
            userModel
                .createUser(
                    ko.vmToJSON(self.user, [
                        'email',
                        'password',
                        'first_name',
                        'last_name',
                        'phone_number_work',
                        'phone_number_cell',
                        'organization',
                        'timezone',
                        'job_title'
                    ])
                )
                .done(function(data) {
                    self.wait(false);
                    var login = new LoginVM();
                    login.username(self.user.email());
                    login.password(self.user.password());
                    login.submit();
                    location.hash = 'greenboxes';
                })
                .fail(function(err) {
                    self.wait(false);
                    self.error(err);
                    self.errorDisplayMessage(err.responseJSON.error.description);
                    console.log(err);
                });
        } else {
            self.formErrors.showAllMessages();
            self.wait(false);
            console.log(self.formErrors());
        }
    };

    self.init = function() {
        self.checkUserStatus();
        if (self.registerEmail()) {
            self.user.email(self.registerEmail());
            self.user.confirmEmail(self.registerEmail());
        }
        if (self.nextViewMode() == self.mode) {
            self.loading('done');
        }
    };

    self.init();
}

module.exports = RegisterComponentViewModel;
