var ko = require('../ExtendedKnockout.js');
var greenboxModel = require('../model/greenbox.js');
var moment = require('moment-timezone');
var swal = require('sweetalert');

function GreenboxComponentViewModel(params) {
	var self = this;
	self.mode = 'greenbox';

	/* API Response Errors */
	self.error = ko.observable();
	self.errorDisplayMessage = ko.observable();

	/* Component State */
	self.id = params.id;
	self.userId = ko.observable().subscribeTo('userId', true);
	self.userRole = ko.observable().subscribeTo('userRole', true);
	self.userTimezone = ko.observable().subscribeTo('userTimezone', true);
	self.timezoneAbbreviation = ko.computed(function() {
		return moment.tz(self.userTimezone()).format('Z z');
	});
	self.authToken = ko.observable().subscribeTo('authToken', true);
	
	self.nextViewMode = ko.observable().subscribeTo('nextViewMode', true);
	self.loading = ko.observable().syncWith('loading');

	self.editGreenbox = ko.observable(false);
	self.showLog = ko.observable(false);
	self.loadingManual = ko.observable(false);
	self.updateSuccess = ko.observable(false);

	self.wait = ko.observable(false);

	self.nextViewMode.subscribe(function(mode) {
		if(mode == self.mode) {
			self.resetFields();
			self.retrieve();
		}
	});

	/* Model Data */
	self.greenbox = new greenboxModel();
	self.originalGreenbox = new greenboxModel();
	
	self.nonMemberGroups = ko.observableArray();
	self.localGroup = ko.observableArray();
	
	self.localGroupAccessAllowed = ko.observable(false);
	self.isAdmin = ko.observable(false);

	self.greenboxLog = ko.observableArray([]);
	
	/* Methods */
	self.toggleEditGreenbox = function() {
		if(!self.updateSuccess() && self.editGreenbox()) {
			self.retrieve();
		}

		self.editGreenbox(!self.editGreenbox());
		self.updateSuccess(false);
	};

	self.toggleLog = function() {
		if(!self.showLog()) {
			self.retrieveLog();
			self.showLog(true);
		}
		else {
			self.showLog(false);
		}

	};
	
	self.retrieve = function() {
		self.resetFields();
		greenboxModel.retrieveGreenbox(self.id())
			.done(function(data) {
				if(data.isAdmin)
					self.isAdmin(true);
				ko.JSONtoVm(data.greenbox, self.greenbox);
				
				if(data.greenbox.nonMemberGroups)
					self.getGroupTypes(data.greenbox.nonMemberGroups);
				else
					self.getGroupTypes();

				var originalGreenbox = JSON.parse(JSON.stringify(data.greenbox));
				ko.JSONtoVm(originalGreenbox, self.originalGreenbox);
				self.loading('done');
			})
			.fail(function(err) {
				self.error(err);
				self.errorDisplayMessage(err);
				console.log(err);
				self.loading('done');
				location.hash = 'greenboxes';
			});
	};

	self.getGroupTypes = function(nonMemberGroupsArray) {
		if(nonMemberGroupsArray && nonMemberGroupsArray.length > 0) {
			ko.utils.arrayForEach(self.greenbox.groups(), function(group) {
				if(group.local_group) {
					if(self.userRole() == 'root') {
						self.localGroupAccessAllowed(true);
					}
					else if(nonMemberGroupsArray.indexOf(group.id) >= 0) {
						self.localGroupAccessAllowed(false);
					}
					else {
						self.localGroupAccessAllowed(true);
					}
					self.localGroup(group);					
				}
			});

			self.greenbox.groups.remove(self.localGroup());

			ko.utils.arrayForEach(self.greenbox.groups(), function(group) {
				if(nonMemberGroupsArray.indexOf(group.id) >= 0) {
					self.nonMemberGroups.push(group);				
				}
			});

			ko.utils.arrayForEach(self.nonMemberGroups(), function(group) {
				self.greenbox.groups.remove(group);			
			});
		}
		else {
			ko.utils.arrayForEach(self.greenbox.groups(), function(group) {
				if(group.local_group) {
					self.localGroup(group);
					if(self.userRole() == 'root')
						self.localGroupAccessAllowed(true);
				}
			});
			self.greenbox.groups.remove(self.localGroup());
		}
	};

	self.deleteGreenbox = function() {
		swal({
			title: "Confirm Delete greenBOX",
			text: "Delete greenBOX?",
			type: "warning",
			html: true,
			showCancelButton: true,
			confirmButtonText: "Delete",
			closeOnConfirm: false
		},
		     function() {
			     greenboxModel.deleteGreenbox(self.id())
				     .done(function(data) {
					     if(data.success) {
						     swal("Deleted", "Greenbox has been deleted", "success");
						     location.hash = 'greenboxes';
					     }
				     })
				     .fail(function(err) {
					     console.log(err);
				     });			     
		     });
	};

	self.getManual = function() {
		self.loadingManual(true);
		greenboxModel.retrieveManual(self.authToken(), function(data) {
		});
	};

	self.retrieveLog = function() {
		self.wait(true);
		greenboxModel.retrieveLog(self.id())
			.done(function(data) {
				if(data.logEntries.length > 0)
					self.greenboxLog(data.logEntries);
				else
					self.greenboxLog([]);
				
				self.wait(false);
			})
			.fail(function(err) {
				self.wait(false);
				console.log(err);
			});
	};

	self.exportLog = function() {
		var textFile = null;

		function toCSV() {
			var csvTextFile = 'Timezone' + ',' + self.timezoneAbbreviation() + '\r\n';
			self.greenboxLog().forEach(function(logEntry) {
				var curEventEnum;
				if(logEntry.event == 'Offline')
					curEventEnum = 0;
				else if(logEntry.event == 'Online')
					curEventEnum = 1;
				else if(logEntry.event == 'Accessed')
					curEventEnum = 2;
				
				csvTextFile +=
					curEventEnum + ',' +
					logEntry.user.email + ',' +
					self.formatDate(logEntry.createdAt) + '\r\n';
			});
			return csvTextFile;
		};

		function generateTextFile(text, textFile) {
			var data = new Blob([text], {type: 'text/plain'});

			if(textFile !== null) {
				URL.revokeObjectURL(textFile);
			};

			textFile = URL.createObjectURL(data);
			return textFile;
		};

		return generateTextFile(toCSV(), textFile);
	};

	self.nameExportLog = function() {
		return self.greenbox.name() + '-' + new Date().toString() + '.csv';
	};
	
	self.formatDate = function(date) {
		var formattedDate = moment.tz(date, self.userTimezone()).format('YYYY-MM-DD HH:mm:ss');
		return formattedDate.toString();
	};

	self.resetFields = function() {
		self.isAdmin(false);
		self.nonMemberGroups([]);
		self.greenbox.groups([]);
		self.localGroup([]);
		self.loadingManual(false);
		self.showLog(false);
		self.greenboxLog([]);
		self.wait(false);
		self.error(null);
		self.errorDisplayMessage(null);
	};

	self.update = function() {
		self.wait(true);
		var errors = ko.validation.group([self.greenbox.name, self.greenbox.description]);
		if(errors().length === 0) {
			var updatedGreenbox = {
				name: self.greenbox.name(),
				description: self.greenbox.description()
			};
			greenboxModel.updateGreenbox(self.id(), updatedGreenbox)
				.done(function(data) {
					self.wait(false);
					self.updateSuccess(true);
					self.editGreenbox(false);
				})
				.fail(function(err) {
					console.log(err);
					self.error(err);
					self.errorDisplayMessage("Server replied with error");
					self.wait(false);
				});
		}
		else {
			self.wait(false);
		}
	};

	self.accessRemotely = function() {
		if(!self.greenbox.online()) {
			swal({
				title: "Please first verify the greenBOX is connected to your WiFi network and then try restarting it.",
				text: 'Connect Anyway?',
				type: "warning",
				html: true,
				showCancelButton: true,
				showConfirmButton: true,
				confirmButtonText: "Connect",
				closeOnConfirm: true
			},
			     function() {
				 	 window.open(
				 		 '#remoteaccess/' + self.id(),
				 		 '_blank'
				 	 );
			     });
		} else {
			window.open(
				'#remoteaccess/' + self.id(),
				'_blank'
			);
		}

	};
	
	/* Initialization */
	if(self.nextViewMode() == self.mode) {
		self.resetFields();
		self.retrieve();
	}
}

module.exports = GreenboxComponentViewModel;

