var ko = require('../ExtendedKnockout.js');

function ResponseCalloutViewModel(params) {
	var self = this;

	self.type = null;
	self.message = null;
	self.trigger = null;
	self.close = null;

	self.parseParams = function() {
		self.type = ko.observable(params.type);
		self.message = ko.observable(params.message);
		self.trigger = params.trigger;

		if(typeof close == 'function')
			self.close = params.close;
		else
			self.close = function() { };
	};

	self.parseParams();
	
}

module.exports = ResponseCalloutViewModel;
