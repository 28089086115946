// in ./js/model/greenbox.js
var ko = require('../ExtendedKnockout.js');

function greenbox() {
	var self = this;
	
	self.name = ko.observable().extend({
		required: true,
		minLength: 2,
		maxLength: 64
	});

	self.description = ko.observable().extend({
		maxLength: 256
	});

	self.serial_number = ko.observable().extend({
		required: true
	});

	self.online = ko.observable();
	self.groups = ko.observableArray();
}


// Create Greenbox
greenbox.createGreenbox = function(greenbox) {
	return $.ajax({
		url: '/v1/greenboxes',
		type: 'POST',
		data: JSON.stringify({
			greenbox: greenbox
		})
	});
};

// Delete Greenbox
greenbox.deleteGreenbox = function(id) {
	return $.ajax({
		url: '/v1/greenboxes/' + id,
		type: 'DELETE'
	});
};

// Retrieve Access Token
greenbox.getErasToken = function(id) {
	return $.ajax({
		url: '/v1/greenboxes/' + id + '/token',
		type: 'GET'
	});
};

// Retrieve Greenbox
greenbox.retrieveGreenbox = function(id) {
	return $.ajax({
		url: '/v1/greenboxes/' + id,
		type: 'GET'
	});
};

// Retrieve Greenboxes
greenbox.retrieveGreenboxes = function(search_string) {
	var url = '/v1/greenboxes';

	if(search_string)
		url += '?search=' + search_string;

	return $.ajax({
		url: url,
		type: 'GET'
	});
};

// Update Greenbox
greenbox.updateGreenbox = function(id, updatedGreenbox) {
	return $.ajax({
		url: '/v1/greenboxes/' + id,
		type: 'PUT',
		data: JSON.stringify({
			greenbox: updatedGreenbox
		})
	});
};

greenbox.retrieveManual = function(token, cb) {
	var request = new XMLHttpRequest();
	request.onreadystatechange = function() {
		if(request.readyState == 4 && request.status == 200) {
			cb();	
		}
	};
	request.open("GET", "https://api.ecogate.com/v1/static/manuals/greenboxNXT-Manual.pdf", true);
	request.responseType = "blob";
	request.setRequestHeader('Authorization', 'JWT ' + token);
	request.onload = function (e) {
		if (this.status === 200) {
			// create `objectURL` of `this.response` : `.pdf` as `Blob`
			var file = window.URL.createObjectURL(this.response);
			var a = document.createElement("a");
			a.href = file;
			a.download = this.response.name || "greenboxNXT-Manual.pdf";
			document.body.appendChild(a);
			a.click();
			// remove `a` following `Save As` dialog,
			// `window` regains `focus`
			window.onfocus = function () {
				document.body.removeChild(a);
			};
		};
		
	};
	request.send();
};

greenbox.retrieveLog = function(id) {
	return $.ajax({
		url: '/v1/greenboxes/' + id + '/log',
		type: 'GET'
	});
};

module.exports = greenbox;
