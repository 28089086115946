var ko = require('./ExtendedKnockout.js');
var utils = require('./utils.js');
var jwt = require('jwt-simple');
var moment = require('moment-timezone');

function EcogateApi(options) {

	var self = this;

	// Make JQuery use json by default after
	// instantiating the EcogateAPI instance
	$.ajaxSetup({
		beforeSend: function(xhr, options) {
			options.url = self.url + options.url;
		},
		dataType   : 'json',
		contentType: 'application/json; charset=UTF-8',
	});

	/* DATA */

	// Set up the correct URL based on what is configured in
	// main.js
	self.url = options.url;
	if(options.hasOwnProperty('port')) {
		self.port = options.port;
		self.url += ':' + self.port;
	}
	self.secure = options.secure;

	// Keep track of the user's logged in state
	self.authToken = ko.observable().syncWith('authToken', true);
	self.userId = ko.observable().syncWith('userId', true);
	self.userRole = ko.observable().syncWith('userRole', true);

	// Update the default Authorization header of all
	// JQuery requests if we have a good token
	self.authToken.subscribe(function(token) {
		if(token) {
			// Set the authorization header
			$.ajaxSetup({
				beforeSend: function(xhr, options) {
					options.url = self.url + options.url;
				},
				dataType   : 'json',
				contentType: 'application/json; charset=UTF-8',
				headers: {"Authorization": "JWT " + token }
			});
			localStorage.setItem("authToken", token);
		}
		// "Logout"
		else {
			$.ajaxSetup({
				beforeSend: function(xhr, options) {
					options.url = self.url + options.url;
				},
				dataType   : 'json',
				contentType: 'application/json; charset=UTF-8',
				headers: {"Authorization": "JWT " }
			});
		}
	});

	self.userId.subscribe(function(id) {
		localStorage.setItem("userId", id);
	});

	self.userRole.subscribe(function(role) {
		localStorage.setItem("userRole", role);
	});
	
}

module.exports = EcogateApi;
