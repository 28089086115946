var ko = require('../ExtendedKnockout.js');
var utils = require('../utils.js');

function ErrorComponentViewModel() {
  var self = this;

  /* Component State */
  self.viewMode = ko.observable(null).subscribeTo('view-mode', true);
  self.viewMode.subscribe(function(mode) {
    if(mode == 'error')
      self.displayError();
  });

  /* Data */
  self.errorStatus = ko.observable().subscribeTo('errorStatus', true);
  self.errorDisplayMessage = ko.observable().subscribeTo('errorDisplayMessage', true);

  /* Methods */
  self.displayError = function() {
    if(self.errorDisplayMessage instanceof Error) {
      if(self.errorStatus() == 500)
        self.errorDisplayMessage("Internal Server Error");
      else if(self.errorStatus() == 404)
        self.errorDisplayMessage("Resource Not Found");
      else if(self.errorStatus() == 403 || self.errorStatus == 401)
        self.errorDisplayMessage("Unauthorized Access");
      else
        self.errorDisplayMessage("Internal Server Error");
    }
  };

}

module.exports = ErrorComponentViewModel;
