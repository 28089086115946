// in ./js/model/group.js
var ko = require('../ExtendedKnockout.js');

var group = { };

var group = function() {
	
	this.name = ko.observable().extend({
		required: true,
		maxLength: 50
	});
	this.description = ko.observable().extend({
		maxLength: 256
	});
	this.local_group = ko.observable();
	this.root_group = ko.observable();
	this.users = ko.observableArray();
	this.greenboxes = ko.observableArray();
};

// Add Greenbox to Group
group.addGreenbox = function(id, greenbox_id) {
	return $.ajax({
		url: '/v1/groups/' + id + '/addgreenbox',
		type: 'POST',
		data: JSON.stringify({
			greenbox_id: greenbox_id
		})
	});
};

// Create Group
group.createGroup = function(group) {
	return $.ajax({
		url: '/v1/groups',
		type: 'POST',
		data: JSON.stringify({
			group: group
		})
	});
};

// Delete Group
group.deleteGroup = function(id) {
	return $.ajax({
		url: '/v1/groups/' + id,
		type: 'DELETE'
	});
};

// Invite User To Group
group.inviteUser = function(id, email, asAdmin) {
	return $.ajax({
		url: '/v1/groups/' + id + '/inviteuser',
		type: 'POST',
		data: JSON.stringify({
			email: email,
			asAdmin: asAdmin
		})
	});
};

// Retrieve Group
group.retrieveGroup = function(id) {
	return $.ajax({
		url: '/v1/groups/' + id,
		type: 'GET'
	});
};

// Retrieve Groups
group.retrieveGroups = function(search_string) {
	var url = '/v1/groups';

	if(search_string)
		url += '?search=' + search_string;

	return $.ajax({
		url: url,
		type: 'GET'
	});
};

// Update Group
group.updateGroup = function(id, updatedGroup) {
	return $.ajax({
		url: '/v1/groups/' + id,
		type: 'PUT',
		data: JSON.stringify({
			group: updatedGroup
		})
	});
};

module.exports = group;

