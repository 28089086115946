var ko = require('../ExtendedKnockout.js');
var utils = require('../utils.js');
var userModel = require('../model/user.js');

function SendResetPasswordComponentViewModel(params) {
	var self = this;
	self.mode = 'forgotpassword';

	/* API Response Errors */
	self.error = ko.observable();
	self.errorDisplayMessage = ko.observable();

	/* Component State */
	self.nextViewMode = ko.observable().subscribeTo('nextViewMode', true);
	self.loading = ko.observable().syncWith('loading', true);
	self.resetEmailSent = ko.observable(false);
	self.wait = ko.observable(false);

	self.nextViewMode.subscribe(function(mode) {
		if(mode == self.mode) {
			self.resetFields();
			self.loading('done');			
		}
	});
	
	/* Model Data */
	self.email = ko.observable()
		.extend({required: true,
		         email: true});

	/* Methods */
	self.reset = function() {
		var errors = ko.validation.group([self.email]);
		self.wait(true);
		if(errors().length === 0) {
			userModel.resetPassword(self.email())
				.done(function(data) {
					self.resetEmailSent(true);
					self.wait(false);
				})
				.fail(function(err) {
					self.error(err);
					self.errorDisplayMessage(err);
					console.log(err);
					self.resetEmailSent(false);
					self.wait(false);
				});			
		}
		else {
			errors.showAllMessages();
			self.wait(false);
		}

	};

	self.resetFields = function() {
		self.email(null);
		self.resetEmailSent(false);
		self.email.isModified(false);
	};

	/* Init */
	if(self.nextViewMode() == self.mode)
		self.loading('done');
}

module.exports = SendResetPasswordComponentViewModel;
