global.$ = global.jQuery = require('jquery');
const config = require('../config.json');

var ko = require('./ExtendedKnockout.js');
var MainView = require('./MainViewModel.js');
require('foundation-sites');
require('sammy');

// Global access token for now
global.accessToken = null;

// Register our components with Knockout
require('./RegisterComponents.js')(ko);

// The vm variable in not mangled by the build system so it's accessible in the
// window scope for easy debugging in a browser
var vm = new MainView({
    api: config.api
});

// Bind once the page has loaded
window.onload = function() {
    ko.applyBindings(vm);
};
