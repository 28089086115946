var ko = require('../ExtendedKnockout.js');
var greenboxModel = require('../model/greenbox.js');

function CreateGreenboxComponentViewModel(params) {
  var self = this;

  /* API Response Errors */
  self.error = ko.observable();
  self.errorDisplayMessage = ko.observable();

  /* Model Data */
  self.name = ko.observable().extend({required: true});
  self.description = ko.observable();
  self.serial_number = ko.observable().extend({required: true});

  /* Component State */
  self.active = params.active;

  /* Methods */
  self.createGreenbox = function() {
    var greenbox = {
      name: self.name(),
      description: self.description(),
      serial_number: self.serial_number()
    };

    greenboxModel.createGreenbox(greenbox)
      .done(function(data) {
        location.hash = 'greenboxes/' + data.greenbox_id;
      })
      .fail(function(err) {
        self.error(err);
        self.errorDisplayMessage(err.responseJSON.error.description);
        console.log(err);
      });
  };

  self.validateFields = function() {

  };
}

module.exports = CreateGreenboxComponentViewModel;
