var ko = require('../ExtendedKnockout.js');
var greenboxModel = require('../model/greenbox.js');

function GreenboxListComponentViewModel(params) {
	var self = this;
	self.mode = 'greenboxes';

	/* API Response Errors */
	self.error = ko.observable();
	self.errorDisplayMessage = ko.observable();
	self.id = ko.observable().subscribeTo('userId', true);

	self.filterValue = localStorage.getItem("filterChoice");
	localStorage.setItem('filterValue', self.filterChoice);
	
	// localStorage.getItem('key') => value
	// localStorage.setItem('key', value)

	/* Component State */
	self.nextViewMode = ko.observable(null).subscribeTo('nextViewMode', true);
	self.userRole = ko.observable().subscribeTo('userRole', true);
	self.loading = ko.observable().syncWith('loading');

	self.nextViewMode.subscribe(function(mode) {
		if(mode == self.mode) {
			self.resetFields();
			self.retrieve();
		}
	});

	self.displayCreateGreenbox = ko.observable(false);
	self.filterChoice = ko.observable(0);

	
	/* Model Data */
	self.greenboxes = ko.observableArray();
	self.shownGreenBoxes = ko.observableArray();

	/* Methods */
	self.retrieve = function() {
		greenboxModel.retrieveGreenboxes()
			.done(function(data) {
				// console.log(data.greenboxes);
				self.shownGreenBoxes.removeAll();
				self.greenboxes(data.greenboxes);

				if(self.filterChoice() === 0) {
					self.greenboxes(data.greenboxes);
				}
				else if(self.filterChoice() === 1) {
					for(i = 0; i < data.greenboxes.length; i++) {
						if(data.greenboxes[i].online === true ) {
							self.shownGreenBoxes.push(data.greenboxes[i]);
						}
					}
					self.greenboxes(self.shownGreenBoxes());
				}
				else if(self.filterChoice() === 2) {
					for(i = 0; i < data.greenboxes.length; i++) {
						if( (data.greenboxes[i].online === null) || (data.greenboxes[i].online === false) ) {
							self.shownGreenBoxes.push(data.greenboxes[i]);
						}
					}
					self.greenboxes(self.shownGreenBoxes());
				}
				self.loading('done');
			})
			.fail(function(err) {
				self.error(err);
				self.errorDisplayMessage(err);
				self.loading('done');
			});
	};

	self.toggleCreateGreenbox = function() {
		self.displayCreateGreenbox(!self.displayCreateGreenbox());
	};

	self.remoteAccess = function(id) {
		greenboxModel.getErasToken(id)
			.done(function(data) {
				location = "https://eras.ecogate.com/generatetoken/" + data.token;
			})
			.fail(function(err) {
				self.error(err);
				self.errorMessage(err);
			});
	};

	self.resetFields = function() {
		self.displayCreateGreenbox(false);
	};

	self.clickShowAll = function() {
		self.filterChoice(0);
		self.retrieve();
	};

	self.clickShowOnline = function() {
		self.filterChoice(1);
		self.retrieve();
	};

	self.clickShowOffline = function() {
		self.filterChoice(2);
		self.retrieve();
	};

	/* Initialization */
	if(self.nextViewMode() == self.mode) {
		self.retrieve();
		self.resetFields();
	}
	
}

module.exports = GreenboxListComponentViewModel;
