var ko = require('../ExtendedKnockout.js');
var groupModel = require('../model/group.js');

function CreateGroupComponentViewModel(params) {
	var self = this;

	/* API Response Errors */
	self.error = ko.observable();
	self.errorDisplayMessage = ko.observable();

	/* Component State */
	self.active = params.active;

	self.displayCreateGroup = ko.observable(false);

	/* Model Data */
	self.name = ko.observable().extend({required: true});
	self.description = ko.observable();

	/* Methods */
	self.createGroup = function() {
		var errors = ko.validation.group(self.name);
		
		var group = {
			name: self.name(),
			description: self.description(),
		};

		if(errors().length === 0) {
			groupModel.createGroup(group)
				.done(function(data) {
					location.hash = 'group/' + data.id;
					self.name(null);
					self.description(null);
					self.name.isModified(false);
				})
				.fail(function(err) {
					self.error(err);
					self.errorDisplayMessage(err);
					console.log(err);
				});
		}
		else {
			errors.showAllMessages(true);
		}
	};

	self.toggleCreateGroup = function() {
		self.displayCreateGroup(!self.displayCreateGroup());
	};
}

module.exports = CreateGroupComponentViewModel;
