var ko = require('../ExtendedKnockout.js');
var userModel = require('../model/user.js');
var groupModel = require('../model/group.js');
var swal = require('sweetalert');

function UserComponentViewModel(params) {
	var self = this;
	self.mode = 'user';

	/* API Response Errors */
	self.error = ko.observable();
	self.errorDisplayMessage = ko.observable().publishOn('errorDisplayMessage');

	/* Component State */
	self.nextViewMode = ko.observable().subscribeTo('nextViewMode', true);
	self.loading = ko.observable().syncWith('loading', true);
	self.userId = ko.observable().subscribeTo('activeUserId', true);
	self.userRole = ko.observable().subscribeTo('userRole', true);

	self.nextViewMode.subscribe(function(mode){
		if(mode == self.mode)
			self.retrieve();
	});

	self.wait = ko.observable(false);
	self.editInfo = ko.observable(false);
	self.editLogin = ko.observable(false);
	self.editEmail = ko.observable(false);
	self.editPassword = ko.observable(false);
	self.updateSuccess = ko.observable(false);

	self.groupArray = ko.observableArray();
	self.greenboxArray = ko.observableArray();
	self.groupsExist = ko.observable(false);
	self.greenboxesExist = ko.observable(false);

	/* Model Data */
	self.user = new userModel();
	self.name = ko.computed(function() {
		return self.user.first_name() + ' ' + self.user.last_name();
	});

	/* Methods */
	self.toggleEditInfo = function() {
		self.editInfo(!self.editInfo());
		self.editLogin(false);
		self.editEmail(false);
		self.editPassword(false);
	};

	self.toggleEditLogin = function() {
		self.editLogin(!self.editLogin());
		self.editInfo(false);
		self.editEmail(false);
		self.editPassword(false);
	};

	self.toggleEditEmail = function() {
		self.editEmail(!self.editEmail());
	};

	self.toggleEditPassword = function() {
		if(self.editPassword()) {
			if(self.user.changedVals.hasOwnProperty('password')) {
				self.user.changedVals.password(null);
				self.user.changedVals.password.clearError();
				delete self.user.changedVals.password;
			}
			self.editPassword(false);
		}
		else {
			self.editPassword(true);
		}
	};
	
	self.retrieveIndivGroup = function(param) {
		groupModel.retrieveGroup(param)
			.done(function(data) {
				for(i = 0; i < data.group.users.length; i++) {
					if(Number(self.userId()) === data.group.users[i].id) {
						self.groupArray.push(data.group);
						self.groupsExist(true);

						if(data.group.greenboxes.length > 0) {
							self.greenboxesExist(true);
							for(j = 0; j < data.group.greenboxes.length; j++) {
								self.greenboxArray.push(data.group.greenboxes[j]);
							}
						}

						break;
					}
				}
				self.loading('done');
			})
			.fail(function(err) {
				self.error(err);
				self.errorDisplayMessage(err.responseJSON.error.description);
				console.log(err);
			});
	};

	self.retrieve = function() {
		groupModel.retrieveGroups()
			.done(function(data) {
				for(i = 0; i < data.groups.length; i++) {
					self.retrieveIndivGroup(data.groups[i].id);
				}
				self.loading('done');
			})
			.fail(function(err) {
				self.error(err);
				self.errorDisplayMessage(err);
				console.log(err);
			});

		self.groupArray.removeAll();
		self.greenboxArray.removeAll();
		self.groupsExist(false);
		self.greenboxesExist(false);

		userModel.retrieveUser(self.userId())
			.done(function(data) {
				ko.JSONtoVm(data.user, self.user);
				self.loading('done');
				self.user.init();
			})
			.fail(function(err) {
				self.error(err);
				self.errorDisplayMessage(err);
				self.loading('done');
			});
	};
	
	self.update = function() {
		self.wait(true);
		self.user.checkErrors();
		if(self.user.formErrors().length === 0 && !$.isEmptyObject(self.user.changedVals)) {
			var updatedUser = ko.vmToJSON(self.user.changedVals);
			userModel.updateUser(self.userId(), updatedUser)
				.done(function(data) {
					self.updateSuccess(true);
					self.wait(false);
				})
				.fail(function(err) {
					self.error(err);
					self.errorDisplayMessage(err);
					console.log(err);
					self.wait(false);
				});
		}
		else {
			self.user.formErrors.showAllMessages();
			self.wait(false);
		}
	};

	self.deleteUser = function () {
		swal({
			title: "Confirm Delete User",
			text: "Delete user?",
			type: "warning",
			html: true,
			showCancelButton: true,
			confirmButtonText: "Delete",
			closeOnConfirm: false
		},
		     function() {
			     userModel.deleteUser(self.userId())
				     .done(function(data) {
					     if(data.success) {
						     swal("Deleted", "User has been deleted", "success");
						     location.hash = 'users';
					     }
				     })
				     .fail(function(err) {
					     console.log(err);
				     });
			     
		     });
	};

	self.resetFields = function() {
		self.updateSuccess(false);
	};

	/* Initialization */
	if(self.nextViewMode() == self.mode) {
		self.retrieve();
	}
}

module.exports = UserComponentViewModel;
