var ko = require('../ExtendedKnockout.js');
var greenboxModel = require('../model/greenbox.js');

function remoteAccessComponentViewModel(params) {
	var self = this;

	self.error = ko.observable();
	self.errorDisplayMessage = ko.observable();
	
	self.mode = "remoteaccess";

	self.nextViewMode = ko.observable().subscribeTo('nextViewMode', true);
	self.loading = ko.observable().syncWith('loading', true);
	self.id = ko.observable().subscribeTo('activeGreenboxId', true);
	self.remoteAccessToken = ko.observable();

	self.nextViewMode.subscribe(function(mode) {
		if(mode == self.mode && self.id() ) {
			self.getRemoteAccessToken();
			self.loading('done');
		}

	});

	self.getRemoteAccessToken = function() {
		greenboxModel.getErasToken(self.id())
			.done(function(data) {
				self.remoteAccessToken(data.token);
				location = "https://eras.ecogate.com/generatetoken/" + self.remoteAccessToken();
			})
			.fail(function(err) {
				self.error(err);
				self.errorDisplayMessage(err);
				console.log(err);
			});
	};

	if(self.nextViewMode() == self.mode && self.id()) {
		self.getRemoteAccessToken();
		self.loading('done');
	}

}

module.exports = remoteAccessComponentViewModel;
