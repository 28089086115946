var ko = require('../ExtendedKnockout.js');
var utils = require('../utils.js');
var userModel = require('../model/user.js');

function LoginComponentViewModel() {
	var self = this;
	self.mode = 'login';

	/* API Response Errors */
	self.error = ko.observable();
	self.errorDisplayMessage = ko.observable();

	/* Component State */
	self.nextViewMode = ko.observable(null).subscribeTo('nextViewMode', true);
	self.loading = ko.observable().syncWith('loading');
	self.userRole = ko.observable().subscribeTo('userRole', true);
	self.registerEmail = ko.observable().subscribeTo('registerEmail', true);
	self.wait = ko.observable(false);

	self.nextViewMode.subscribe(function(mode) {
		if(self.nextViewMode() == self.mode) {
			self.username(null);
			self.password(null);
			self.username.isModified(false);
			self.password.isModified(false);
			self.loading('done');			
		}
	});

	self.emailLink = ko.observable(false);

	self.checkUserStatus = function() {
		if(self.registerEmail() === undefined) {
		self.emailLink(false);
		}
		else if(self.registerEmail() != undefined) {
			self.emailLink(true);
		}
	};

	/* Model Data */
	self.username = ko.observable()
		.extend({
			required: true,
			email: true});
	self.password = ko.observable()
		.extend({required: true});

	self.authToken = ko.observable();
	self.userId = ko.observable();
	self.userRole = ko.observable();

	self.validationErrors = ko.validation.group(
		[self.username,
		 self.password]
	);
	
	
	
	/* Methods */
	self.submit = function() {
		self.wait(true);
		self.error(null);
		if(self.validationErrors().length === 0) {
			userModel.login(self.username(), self.password())
				.done(function(data) {
					self.authToken.publishOn('authToken');
					self.userId.publishOn('userId');
					self.userRole.publishOn('userRole');
					self.authToken(data.jwt);
					self.userId(data.id);
					self.userRole(data.role);
					location.hash = "groups";
					self.wait(false);						
				})
				.fail(function(err) {
					self.error(err);
					if(err.status == 401) {
						self.errorDisplayMessage("Invalid account email or password");
					}
					else {
						self.errorDisplayMessage("The Ecogate Authentication server is not responding");
						console.log(err);
					}
					self.wait(false);
				});
		}
		else {
			self.validationErrors.showAllMessages();
			self.wait(false);
		}
	};

	if(self.nextViewMode() == self.mode) {
		self.checkUserStatus();
		self.loading('done');
	}

}

module.exports = LoginComponentViewModel;
