var ko = require('knockout');
var zxcvbn = require('zxcvbn');
require('knockout.validation');
require('knockout.mapping');
require('knockout-postbox');

// A computed that we can force to reevaluate
ko.forcibleComputed = function(readFunc, context, options)
{
	var trigger = ko.observable().extend({notify:'always'}),
	    target = ko.computed(function()
	                         {
		    trigger();
		    return readFunc.call(context);
	    }, null, options);

	target.evaluateImmediate = function()
	{
		trigger.valueHasMutated();
	};

	return target;
};

ko.bindingHandlers.hidden = (function() {
	function setVisibility(element, valueAccessor) {
		var hidden = ko.unwrap(valueAccessor());
		$(element).css('visibility', hidden ? 'hidden' : 'visible');
		
	}
	return { init: setVisibility, update: setVisibility };
})();


// Converts a viewModel to JSON with an optional specificFields array
// to select which fields get converted.  Similar to ko.mapping functionality
// except that I don't have to define my viewModel as a mapped VM (gets in the
// way of ko.validation)
ko.vmToJSON = function(viewModel, specificFields) {

	var key, i;
	var fields = [];

	if((specificFields !== null || specificFields !== undefined) &&
	   specificFields instanceof Array) {
		for(i in specificFields) {
			if(viewModel.hasOwnProperty(specificFields[i])) {
				fields.push(specificFields[i]);
			}
			else {
				throw Error("No property " + specificFields[i] + " in viewModel");
			}
		}
	}
	else if(specificFields === null || specificFields === undefined) {
		fields = [];
		for(key in viewModel) {
			if(viewModel.hasOwnProperty(key))
				fields.push(key);
		}
	}
	else
		throw new Error("specificFields parameter improper type");

	var model = { };
	for(i in fields) {
		key = fields[i];
		if((ko.isObservable(viewModel[key]) || ko.isComputed(viewModel[key])) &&
		   viewModel[key]() != undefined) {
			model[key] = viewModel[key]();
		}
		else if(!ko.isObservable(viewModel[key]) && viewModel[key]) {
			model[key] = viewModel[key];
		}
	}
	return model;
};

// Sets the values of a viewModel based on the
// values of a JSON object
ko.JSONtoVm = function(model, viewModel) {
	for(var key in model) {
		if(model.hasOwnProperty(key) && viewModel.hasOwnProperty(key)) {
			if(ko.isObservable(viewModel[key])) {
				viewModel[key](model[key]);
			}
			else {
				viewModel[key] = model[key];
			}
		}
	}
};


/* From the ko.validation github:
 * Ensures a field has the same value as another field (E.g. "Confirm Password" same as "Password"
 * Parameter: otherField: the field to compare to
 * Example
 *
 * viewModel = {
 *   var vm = this;
 *   vm.password = ko.observable();
 *   vm.confirmPassword = ko.observable();
 * }
 * viewModel.confirmPassword.extend( areSame: { params: viewModel.password, message: "Confirm password must match password" });
 */
ko.validation.rules.areSame = {
	getValue: function (o) {
		return (typeof o === 'function' ? o() : o);
	},
	validator: function (val, otherField) {
		return val === this.getValue(otherField);
	},
	message: 'The fields must have the same value'
};

/* Enforce ZXCVBN requirement
 *
 * vm.password = ko.observable().extend({ zxcvbn_score: 2});
 *
 */
ko.validation.rules.zxcvbn_score = {
	validator: function (val, zxcvbn_score) {
		var result;
		if(val === undefined || val === null || val.length === 0)
			return false;
		else {
			result = zxcvbn(val);
		}

		if(result.score >= zxcvbn_score)
			return true;
		else
			return false;
	},
	message: 'Insufficient password complexity'
};

// KO Validation Options
ko.validation.init({
	registerExtenders: true,
	// Automatically put error messages
	insertMessages: true,
	// Automatically decorate input fields
	decorateInputElement: true,
	decorateElement: true,
	// Default classes force error styling
	errorElementClass: 'is-invalid-input',
	errorMessageClass: 'kov-error',
	// Parse force HTML5 attrbutes
	parseInputAttributes: true,
	writeInputAttributes: true
});

module.exports = ko;
