var ko = require('./ExtendedKnockout.js');
var utils = require('./utils.js');
var jwt = require('jwt-simple');
var EcogateApi = require('./EcogateApi.js');

function MainViewModel(options)
{
	var self = this;

	/* API */
	self.ecogateApi = null;

	/* Settings */
	self.settings =
		{
			api:
			{
				url: null,
				port: null,
				secure: null
			}
		};

	/* Model Data */
	self.viewMode = ko.observable().publishOn('viewMode');
	self.nextViewMode = ko.observable().publishOn('nextViewMode');
	self.loading = ko.observable().syncWith('loading');
	self.loading.subscribe(function(state) {
		if(state == 'done') {
			if(self.nextViewMode()) {
				self.viewMode(self.nextViewMode());
				self.nextViewMode(null);
			}
		}
	});
	
	self.activeGroupId = ko.observable().publishOn('activeGroupId');
	self.activeGreenboxId = ko.observable().publishOn('activeGreenboxId');
	self.activeUserId = ko.observable().publishOn('activeUserId');
	
	self.authToken = ko.observable().syncWith('authToken');
	self.userId = ko.observable().syncWith('userId');
	self.userRole = ko.observable().syncWith('userRole');

	self.loggedIn = ko.computed(function () {
		if(self.authToken() && self.userId() && self.userRole())
			return true;
		else
			return false;
	}).publishOn('loggedIn');
	
	self.verifyKey = ko.observable().publishOn('verifyKey');
	self.resetKey = ko.observable().publishOn('resetKey');
	self.registerEmail = ko.observable().publishOn('registerEmail');

	
	self.numPendingInvites = ko.observable().subscribeTo('pendingInvites', true);
	
	self.navVisible = ko.computed(function() {
		if(self.viewMode() == 'login' ||
		   self.viewMode() == 'verifyemail' ||
		   self.viewMode() == 'register' ||
		   self.viewMode() == 'resetpassword' ||
		   self.viewMode() == 'forgotpassword' ||
		   self.viewMode() == 'faq' ||
		   self.viewMode() == 'error')
			return false;
		else
			return true;
	});

	/* Methods */

	self.setViewMode = function(viewMode, param) {
		var nextViewMode = viewMode;
		if(!self.loggedIn())
			switch(viewMode) {
				case 'register':
					nextViewMode = 'login';
					self.registerEmail(decodeURIComponent(param));
					break;
				case 'verifyemail':
					self.verifyKey(param);
					break;
				case 'resetpassword':
					self.resetKey(param);
					break;
				case 'faq':
					location.assign('faq.html');
					break;
				case 'forgotpassword':
				case 'login':
					break;
				default:
					nextViewMode = 'login';
			}
		else {
			switch(viewMode) {
				case 'user':
					self.activeUserId(param);
					break;
				case 'group':
					self.activeGroupId(param);
					break;
				case 'greenbox':
					self.activeGreenboxId(param);
					break;
				case 'verifyemail':
					self.verifyKey(param);
					break;
				case 'resetpassword':
					self.resetKey(param);
					break;
				case 'register':
					nextViewMode = 'login';
					self.registerEmail(decodeURIComponent(param));
					break;
				case 'invites':
					nextViewMode = 'invites';
					break;
				case 'remoteaccess':
					nextViewMode = 'remoteaccess';
					self.activeGreenboxId(param);
					break;
				case 'faq':
					location.assign('faq.html');
					break;
				case 'profile':
				case 'users':
				case 'groups':
				case 'greenboxes':
					break;
				default:
					nextViewMode = 'greenboxes';
					break;
			}
		}
		self.viewMode('loading');
		self.loading('started');
		self.nextViewMode(nextViewMode);
	};

	/* SammyJS Client-Side Routing */
	self.setRoutes = function() {
		Sammy(function() {
			this.get('#:view', function() {
				self.setViewMode(this.params.view);
			});
			
			this.get('#:view/:param', function() {
				self.setViewMode(this.params.view, this.params.param);
			});
			
			this.get('', function() {
				self.setViewMode('login');
			});
		}).run();
	};

	self.checkLoggedIn = function() {
		var storedToken = localStorage.getItem("authToken");
		var storedId = localStorage.getItem("userId");
		var storedRole = localStorage.getItem("userRole");

		if(utils.isDefined(storedToken) &&
		   utils.isDefined(storedId) &&
		   utils.isDefined(storedRole)) {
			
			var decoded = jwt.decode(storedToken, '', true);
			var expired = decoded.exp <= Date.now();

			if(expired) {
				console.log("Token Expired!");
				self.logOut();
				return;
			}
			self.authToken(storedToken);
			self.userId(storedId);
			self.userRole(storedRole);
		}
	};

	self.logOut = function() {
		localStorage.removeItem("authToken");
		localStorage.removeItem("userId");
		localStorage.removeItem("userRole");
		self.authToken(null);
		self.userId(null);
		self.userRole(null);
		location.hash = 'login';
	};

	self.parseOptions = function(options)
	{
		if(utils.isObject(options))
		{
			// api
			if(utils.isObject(options.api))
			{
				// url
				if(options.api.hasOwnProperty('url'))
				{
					self.settings.api.url = options.api.url;
				}

				// port
				if(options.api.hasOwnProperty('port'))
				{
					self.settings.api.port = options.api.port;
				}

				// secure
				if(options.api.hasOwnProperty('secure'))
				{
					self.settings.api.secure = options.api.secure;
				}
			}
		}
	};

	self.init = function(options)
	{
		self.parseOptions(options);
		self.ecogateApi = new EcogateApi(self.settings.api);
		self.checkLoggedIn();
		self.setRoutes();
	};
	self.init(options);
}

module.exports = MainViewModel;
