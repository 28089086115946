var ko = require('../ExtendedKnockout.js');
var LoginVM = require('./login.js');
var userModel = require('../model/user.js');

function ProfileComponentViewModel(params) {
	var self = this;
	self.mode = 'profile';

	/* API Response Errors */ 
	self.error = ko.observable();
	self.errorDisplayMessage = ko.observable();

	/* Component State */
	self.nextViewMode = ko.observable(null).subscribeTo('nextViewMode', true);
	self.loading = ko.observable().syncWith('loading', true);
	self.userRole = ko.observable().subscribeTo('userRole', true);
	self.userTimezone = ko.observable().publishOn('userTimezone');
	
	self.authToken = ko.observable().syncWith('authToken', true);
	self.id = ko.observable().syncWith('userId', true);

	self.wait = ko.observable(false);
	self.waitVerify = ko.observable(false);
	self.editPassword = ko.observable(false);
	self.verifySuccess = ko.observable(false);
	self.updateSuccess = ko.observable(false);

	self.nextViewMode.subscribe(function(mode) {
		if(mode == self.mode)
			self.retrieve();
	});
	
	/* Model Data */
	self.user = new userModel();
	self.name = ko.computed(function() {
		return self.user.first_name() + ' ' + self.user.last_name() + 'extra';
	});

	self.user.timezone.subscribe(function(timezone) {
		self.userTimezone(timezone);
	});

	self.availableTimezones = ko.observableArray(userModel.getTimeZones());

	self.confirmPassword = ko.observable().extend({
		required: true,
		areSame: {
			params: self.user.password,
			message: "Passwords do not match"
			
		}	
	});

	/* Methods */
	self.toggleEditPassword = function() {
		if(self.user.password()) {
			self.user.password(null);
			self.user.password.isModified(false);
			self.confirmPassword(null);
			self.confirmPassword.isModified(false);
			delete self.user.changedVals.password;
		}

		self.editPassword(!self.editPassword());
	};
	
	self.retrieve = function() {
		userModel.retrieveUser(self.id())
			.done(function(data) {
				ko.JSONtoVm(data.user, self.user);
				self.loading('done');
				self.resetFields();
				self.user.init();
				self.userTimezone(data.user.timezone);
			})
			.fail(function(err) {
				self.error(err);
				self.errorDisplayMessage(err);
				console.log(err);
			});
	};

	self.deleteAccount = function() {
		userModel.deleteUser(self.id())
			.done(function(data) {
				location.hash = 'login';
				localStorage.removeItem('authToken');
				localStorage.removeItem('userId');
				localStorage.removeItem('userRole');
			})
			.fail(function(err) {
				self.error(err);
				self.errorDisplayMessage(err);
				console.log(err);
			});
	};

	self.update = function() {
		self.wait(true);
		self.user.checkErrors();
		if(self.user.formErrors().length === 0 && !$.isEmptyObject(self.user.changedVals)) {
			var updatedUser = ko.vmToJSON(self.user.changedVals);
			userModel.updateUser(self.id(), updatedUser)
				.done(function(data) {
					self.wait(false);
					self.updateSuccess(true);
					self.errorDisplayMessage(null);
				})
				.fail(function(err) {
					self.error(err);
					if(err.responseJSON.error.values[0].message == "email must be unique") {
						self.errorDisplayMessage("Failed to update email.  That address is" +
						                         " already in use.");
						self.user.email.setError("This email is not available");
					}
					else {
						self.errorDisplayMessage("Something went wrong!");						
					}
					self.wait(false);
				});	
		}
		else {
			self.wait(false);
			self.user.formErrors.showAllMessages();
		}
	};

	self.resetFields = function() {
		self.updateSuccess(false);
		self.verifySuccess(false);
		self.editPassword(false);
	};

	self.sendVerifyEmail = function() {
		self.waitVerify(true);
		userModel.sendVerifyEmail(self.id())
			.then(function(data) {
				self.waitVerify(false);
				self.verifySuccess(true);
			})
			.fail(function(err) {
				self.error(err);
				self.errorDisplayMessage(err);
				self.waitVerify(false);
				console.log(err);
			});
	};

	/* Initialization */
	if(self.nextViewMode() == self.mode) {
		if(self.id() && self.authToken() && self.userRole())
			self.retrieve(); 		
	}

}

module.exports = ProfileComponentViewModel; 
